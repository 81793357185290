import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useContext, useEffect, useState } from "react";
import {
  PiChartBarLight,
  PiClockLight,
  PiMoneyLight,
  PiUsersLight,
  PiVideoCameraLight,
} from "react-icons/pi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Reviews from "../CourseDetails/Reviews";
import BankDetailsDialog from "../Payment/BankDetailsDialog";
import VonOnlineCurriculum from "./VonOnlineCurriculum";
import VonOnlineDescription from "./VonOnlineDescription";

import { PopupModal } from "react-calendly";
import client, { config } from "../../api/client";
import { GlobalContext } from "../../context";
import { ActivateCourse } from "../../context/actions/courses";
import { currencyFormatter, fetchUserLocation } from "../../utility";
import CircularProgress from "../Generic/CircularProgress";
import CousesOutline from "../Generic/CousesOutline";
import Discount from "../Generic/Discount";
import LoadingSpinner from "../LoadingSpinner";
import { useIsMobile } from "../hooks/useIsMobile";
export default function VonOnlineCourseDetails() {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [approveLoading, setApproveLoading] = useState(false);
  const {
    authState: { data, loggedIn },
    userCoursesDispatch,
  } = useContext(GlobalContext);
  const user = data?.user || {};
  const [openCalendly, setOpenCalendly] = useState(false);
  const [openBankDetails, setOpenBankDetails] = useState(false);
  const [isCoursePurchased, setIsCoursePurchased] = useState(false);
  const [userLocation, setUserLocation] = useState(null); // State to hold user's location
  const [showPayPal, setShowPayPal] = useState(false);
  const [courses, setCourses] = useState({});
  // const { courses, fetchCourseDetails } = useContext(CourseDetailContext);

  const loadData = async () => {
    setLoading(true);
    try {
      const location = await fetchUserLocation();
      setUserLocation(location);
      const res = (
        await client.get(`/courses/online/online-courses/details/${uuid}`)
      ).data;

      setCourses(res);
    } catch (error) {
      console.log("error fetchimg course details", error.response);
    }
    setLoading(false);
  };
  const loadUserPaymentStatus = async () => {
    try {
      const res = (await client.get(`/subscribers/check/${uuid}`)).data;
      if (res.hasOwnProperty("payment_date")) {
        // payment_date exists
        setIsCoursePurchased(true);
      } else {
        // payment_date does not exist
        setIsCoursePurchased(false);
      }
    } catch (error) {
      console.log(error?.response);
    }
  };
  // Function to fetch user's location

  const currency = userLocation === "NG" ? "NGN" : "USD";
  const price =
    currency === "NGN"
      ? courses?.promo_price_ngn
        ? courses?.promo_price_ngn
        : courses?.price_ngn || 0
      : courses?.promo_price_usd
      ? courses?.promo_price_usd
      : courses?.price_usd || 0;
  useEffect(() => {
    if (loggedIn) {
      loadUserPaymentStatus();
    }

    loadData();
  }, [uuid, userLocation]);
  const approveCourse = async () => {
    setApproveLoading(true);
    const res = await ActivateCourse(
      { user_id: user?.id, course_id: courses?.course_id },
      userCoursesDispatch
    );
    if (res) {
      toast.success("Course successfully Activated");
      setShowPayPal(false);
      navigate(`/course-video/${uuid}`);
    }
    setApproveLoading(false);
  };
  const handlePayment = (e) => {
    if (loggedIn) {
      const price =
        currency === "NGN" ? courses.price_ngn * 100 : courses.price_usd * 100;

      setOpenBankDetails(true);

      // const paystack = new paystackPop();
      // paystack.newTransaction({
      //   key: process.env.REACT_APP_PAYSTACK_KEY,
      //   amount: price,
      //   currency: currency,
      //   email: user.email,
      //   firstname: user.firstname,
      //   lastname: user.lastname,
      //   onSuccess() {
      //     axios({
      //       url: "https://e1xstaging.edoubleone.com/api/subscribers/add",
      //       data: formData,
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //         Authorization: `Bearer ${token}`,
      //       },
      //     })
      //       .then((response) => {
      //         toast.success("payment successful");
      //         navigate(`/course-video/${uuid}`);
      //         console.log("Subscription successful:", response.data);
      //       })
      //       .catch((error) => {
      //         console.error("Error adding subscription:", error);
      //       });
      //   },
      // });
    } else {
      navigate("/sign-in");
    }
  };

  const instructors =
    courses &&
    Array.isArray(courses?.instructors) &&
    courses?.instructors?.includes("undefined")
      ? "John Doe"
      : courses?.instructors && "John Doe";

  //   My code
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const mobile = useIsMobile();
  return showPayPal ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        minHeight: "70vh",
        marginBottom: 40,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 5,
          right: 30,
        }}
      >
        <button
          onClick={() => {
            setShowPayPal(false);
          }}
          // onClick={closeModal}
          className="flex items-center justify-center w-10 h-10 bg-red-500 text-white rounded focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div
        style={{
          width: mobile ? "70%" : "45%",
        }}
      >
        <PayPalScriptProvider
          options={{
            "client-id":
              "AfuCMHbIGOhunRHrRF1XatSyf0ETL8w4JS-gT09_IfmZmFTegMvN8UaHgEW63ko_S_JeSpss0sJJEpyC",
            currency: "USD",
            // "disable-funding": "credit,card", // This option can enable or disable specific funding sources
            "enable-funding": "venmo,credit,card",
          }}
        >
          <PayPalButtons
            createOrder={(data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: courses?.price_usd || 0,
                    },
                  },
                ],
              });
            }}
            onApprove={(e) => {
              approveCourse();
            }}
            onCancel={() => {}}
          />
        </PayPalScriptProvider>
      </div>
    </div>
  ) : loading ? (
    <LoadingSpinner />
  ) : (
    <div className="bg-[#f7f7f7] md:pt-1 pt-14">
      <div className="innersection">
        <BankDetailsDialog
          userLocation={userLocation}
          currency={currency}
          price={price}
          payPalStart={() => {
            setShowPayPal(true);
            setOpenBankDetails(false);
          }}
          course={courses}
          isOpen={openBankDetails}
          onClose={() => {
            setOpenBankDetails(false);
          }}
        />
        <PopupModal
          onModalClose={() => {
            setOpenCalendly(false);
          }}
          open={openCalendly}
          url={config.CalendlyUrl}
          /*
           * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
           * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
           */
          rootElement={document.getElementById("root")}
          text="Click here to schedule!"
          textColor="#ffffff"
          color="#00a2ff"
        />
        <section
          className="mb-[3%] mt-[6%] "
          style={
            {
              // alignItems: "flex-start",
            }
          }
        >
          <div
            className="flex  justify-between"
            style={{
              flexDirection: mobile ? "column" : "row",
              alignItems: mobile ? "" : "center",
              gap: mobile ? 30 : 0,
              width: "100%",
            }}
          >
            <button
              onClick={() => {
                navigate(-1);
              }}
              className={
                " bg-[#d15242] rounded-xl text-white p-4   text-xs flex items-center justify-between  hover:bg-[#fff] hover:text-black"
              }
              style={{
                borderRadius: 50,
                width: 50,
                height: 50,
              }}
            >
              <svg
                style={{
                  marginLeft: "-5%",
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5" // Tailwind CSS classes for size
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7" // Simple left-pointing arrow
                />
              </svg>
              {/* <h1> Back</h1> */}
            </button>
            <div>
              {isCoursePurchased ? (
                <button
                  style={{
                    width: "100%",
                    background: "#d15242",
                    fontSize: 18,
                  }}
                  className="text-lg w-full font-bold rounded text-white px-4 py-5 md:px-4 md:py-4  hover:bg-[#fff] transition-all delay-0 duration-300 ease-out cursor-pointer"
                  onClick={() => {
                    if (loggedIn) {
                      if (user?.is_email_verified) {
                        navigate(`/course-video/${uuid}`);
                      } else {
                        navigate("/verify-useremail");
                      }
                    } else {
                      navigate("/sign-in");
                    }
                  }}
                >
                  START COURSE
                </button>
              ) : courses?.is_free === 1 || courses?.is_free === "1" ? (
                <button
                  style={{
                    width: "100%",
                    background: "#d15242",
                    fontSize: 18,
                  }}
                  className="text-lg w-full font-bold rounded text-white px-4 py-5 md:px-4 md:py-4  hover:bg-[#fff] transition-all delay-0 duration-300 ease-out cursor-pointer"
                  onClick={() => {
                    if (loggedIn) {
                      if (user?.is_email_verified) {
                        approveCourse();
                      } else {
                        navigate("/verify-useremail");
                      }
                      // navigate(`/course-video/${uuid}`);
                    } else {
                      navigate("/sign-in");
                    }
                  }}
                >
                  {approveLoading ? <CircularProgress /> : "START COURSE"}
                </button>
              ) : (
                <button
                  style={{
                    width: "100%",
                    background: "#d15242",
                    fontSize: 18,
                  }}
                  className="text-lg w-full font-bold rounded text-white px-4 py-5 md:px-4 md:py-4  hover:bg-[#fff] transition-all delay-0 duration-300 ease-out cursor-pointer"
                  onClick={() => {
                    if (loggedIn) {
                      if (user?.is_email_verified) {
                        setOpenBankDetails(true);
                      } else {
                        navigate("/verify-useremail");
                      }
                    } else {
                      navigate("/sign-in");
                    }
                  }}
                >
                  PURCHASE COURSE
                </button>
              )}
            </div>
          </div>
          <div>
            <div>
              <p className="text-[#aaa] text-[13px] mb-1 invisible">Teacher</p>
              <p className="text-[15px] text-[#555] hover:text-[#d15242] font-semibold transition-colors delay-0 duration-150 invisible">
                {instructors}
              </p>
            </div>

            <h1 className="text-3xl sm:text-[40px] font-bold">
              {courses?.title}
            </h1>
            <button
              onClick={() => {
                setOpenCalendly(true);
              }}
              style={{
                color: "#d15242",
                marginTop: 5,
              }}
            >
              Learn More
            </button>
          </div>
        </section>

        <section className="md:flex mb-[5%] w-full h-full relative">
          <div className="md:w-1/4 md:mt-0 mt-[10%] bg-[#f1f1f1] text-[#273044] font-semibold h-full">
            <div className="flex items-center gap-4 border-b-solid border-b-[rgb(163,163,163)]/[.3] border-b-[1px] mx-[3%]">
              <div className="text-[28px] text-[#d15242] py-[26px] px-[10px]">
                <PiMoneyLight />
              </div>
              <div
                className="flex"
                style={{
                  flexWrap: "wrap",
                }}
              >
                {" "}
                Price: {"   " + ""}
                {courses?.is_free === 0 && (
                  <>
                    {courses?.promo_price_ngn && userLocation === "NG" && (
                      <p
                        style={{
                          marginRight: 10,
                          marginLeft: 5,
                        }}
                      >
                        {currencyFormatter(
                          userLocation === "NG"
                            ? courses?.promo_price_ngn
                            : courses?.promo_price_usd,
                          { currency: userLocation === "NG" ? "NGN" : "USD" }
                        )}
                      </p>
                    )}
                  </>
                )}
                {courses?.is_free === 1 || courses?.is_free === "1" ? (
                  "Free"
                ) : (
                  <p
                    style={{
                      textDecoration:
                        courses?.promo_price_ngn &&
                        userLocation === "NG" &&
                        "line-through",
                      fontWeight:
                        courses?.promo_price_ngn &&
                        userLocation === "NG" &&
                        300,
                      color:
                        courses?.promo_price_ngn &&
                        userLocation === "NG" &&
                        "#aaa",
                    }}
                  >
                    <>
                      {currencyFormatter(
                        userLocation === "NG"
                          ? +courses?.price_ngn
                          : +courses?.price_usd,
                        { currency: userLocation === "NG" ? "NGN" : "USD" }
                      )}
                    </>
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center gap-4 border-b-solid border-b-[rgb(163,163,163)]/[.3] border-b-[1px] mx-[3%]">
              <div className="text-[28px] text-[#d15242] py-[26px] px-[10px]">
                <PiUsersLight />
              </div>
              <div className="flex">
                <p>Enrolled: {courses?.subscribers_count} students</p>
              </div>
            </div>
            <div className="flex items-center gap-4 border-b-solid border-b-[rgb(163,163,163)]/[.3] border-b-[1px] mx-[3%]">
              <div className="text-[28px] text-[#d15242] py-[26px] px-[10px]">
                <PiClockLight />
              </div>
              <div className="flex">
                <p>Duration: {courses?.duration}</p>
              </div>
            </div>
            <div className="flex items-center gap-4 border-b-solid border-b-[rgb(163,163,163)]/[.3] border-b-[1px] mx-[3%]">
              <div className="text-[28px] text-[#d15242] py-[26px] px-[10px]">
                <PiChartBarLight />
              </div>
              <div className="flex">
                <p>Level: {courses?.course_level_id}</p>
              </div>
            </div>
            <div className="flex items-center gap-4 mx-[3%]">
              <div className="text-[28px] text-[#d15242] py-[26px] px-[10px]">
                <PiVideoCameraLight />
              </div>
              <div className="flex">
                <p>Video: {courses?.lesson?.length} Videos</p>
              </div>
            </div>
          </div>

          <div className="md:w-[73%] md:h-full md:absolute md:top-0 md:left-[27%] relative">
            {(courses?.is_free === 0 || courses?.is_free === "0") &&
              userLocation === "NG" && (
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                  }}
                >
                  <Discount value={courses?.discount || 50} border />
                </div>
              )}

            <img
              src={courses?.images && courses?.images[0]}
              alt={courses?.title}
              className="w-full h-full object-cover"
            />
          </div>
        </section>

        <section className="md:ml-[27%]">
          <nav className="flex flex-wrap mb-[5%] overflow-x-auto">
            <span
              className={
                toggleState === 1
                  ? "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
                  : "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
              }
              onClick={() => toggleTab(1)}
            >
              Description
            </span>
            <span
              className={
                toggleState === 2
                  ? "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
                  : "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
              }
              onClick={() => toggleTab(2)}
            >
              Curriculum
            </span>
            <span
              className={
                toggleState === 3
                  ? "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
                  : "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
              }
              onClick={() => toggleTab(3)}
            >
              Reviews
            </span>
            <span
              className={
                toggleState === 4
                  ? "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
                  : "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
              }
              onClick={() => toggleTab(4)}
            >
              Clarity Session
            </span>
            <span
              className={
                toggleState === 5
                  ? "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
                  : "flex-1 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors whitespace-nowrap"
              }
              onClick={() => toggleTab(5)}
            >
              Master Class
            </span>
          </nav>
          <div className="bg-[#f1f1f1] mb-[5%]">
            <div className={toggleState === 1 ? "" : "hidden"}>
              <VonOnlineDescription courses={courses} />
            </div>
            <div className={toggleState === 2 ? "" : "hidden"}>
              <VonOnlineCurriculum curriculum={courses?.course_content} />
            </div>
            <div className={toggleState === 3 ? "" : "hidden"}>
              <Reviews course={courses} />
            </div>
            <div className={toggleState === 4 ? "" : "hidden"}>
              {courses?.clarity ? (
                <CousesOutline
                  course_link={courses?.clarity?.course_link}
                  date={courses?.clarity?.date}
                  image={courses?.images[0]}
                  program_targets={courses?.clarity?.program_targets}
                  title={courses?.clarity?.title}
                  onClick={() => {}}
                />
              ) : (
                <p className="min-h-[100px] px-[5%] py-[2.5%]">
                  Clarity sessions are interactive classes where you can engage
                  with an instructor to clarify concepts you may not have fully
                  understood from the Courses. Clarity sessions dates will be
                  communicated
                </p>
              )}
            </div>
            <div className={toggleState === 5 ? "" : "hidden"}>
              {courses?.masterclass ? (
                <CousesOutline
                  course_link={courses?.masterclass?.course_link}
                  date={courses?.masterclass?.date}
                  image={courses?.images[0]}
                  program_targets={courses?.masterclass?.program_targets}
                  title={courses?.masterclass?.title}
                  onClick={() => {}}
                />
              ) : (
                <p className="min-h-[100px] px-[5%] py-[2.5%]">
                  Masterclass is a live section aimed at enhancing your job
                  prospects by offering comprehensive interview preparation and
                  a complimentary CV revamp. Master Class session date will be
                  communicated
                </p>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import bankimg from "../../assets/icons/bank.png";
import paypalimg from "../../assets/icons/paypal.webp";
import { currencyFormatter } from "../../utility";
import VonDisplayText from "../AllVon/VonDisplayText/VonDisplayText";
import { useIsMobile } from "../hooks/useIsMobile";
export default function BankDetailsDialog({
  isOpen,
  onClose,
  course,
  userLocation,
  payPalStart = () => {},
}) {
  const [paymentMethod, setPaymentMethod] = useState("bank");
  useEffect(() => {
    if (userLocation === "NG") {
      setPaymentMethod("bank");
    } else {
      setPaymentMethod("paypal");
    }
  }, []);
  const currency = paymentMethod === "bank" ? "NGN" : "USD";
  const price =
    paymentMethod === "bank"
      ? course?.promo_price_ngn
        ? course?.promo_price_ngn
        : course?.price_ngn || 0
      : course?.promo_price_usd
      ? course?.promo_price_usd
      : +course?.price_usd || 0;
  const mobile = useIsMobile();
  const handleSubmit = (e) => {
    toast.success("Payment Will Be Verified Check Back Later...");
    // You can send the data to your server here or perform other actions
    onClose(); // Close the dialog after submitting
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center"
      style={{
        zIndex: 100,
      }}
    >
      <div
        style={{
          position: "relative",
          background: "#fff",
          minHeight: "80vh",
          width: mobile ? "100%" : "70%",
          borderRadius: 20,
          padding: mobile ? 15 : 30,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            marginBottom: 20,
          }}
        >
          <h1
            style={{
              fontSize: 25,
              fontWeight: 500,
              marginBottom: 20,
            }}
          >
            Payment
          </h1>
          <button
            style={{
              background: "#d15242",
              color: "#fff",
              padding: "5px 30px",
              borderRadius: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </button>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              width: mobile ? "100%" : "49%",
            }}
          >
            <div
              style={{
                background: "#fff",
                // padding: 15,
                borderRadius: 25,
                padding: 5,
                display: "flex",
                alignItems: "center",
              }}
            >
              {userLocation === "NG" && (
                <div
                  onClick={() => {
                    setPaymentMethod("bank");
                  }}
                  style={{
                    background: paymentMethod === "bank" ? "#d15242" : "#fff",
                    height: 50,
                    width: "50%",
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: 20,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    padding: 5,
                    color: paymentMethod === "bank" ? "#f7f7f7" : "#000",
                  }}
                >
                  <img
                    src={bankimg}
                    style={{
                      height: "100%",
                      width: "80%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}

              <div
                onClick={() => {
                  setPaymentMethod("paypal");
                }}
                style={{
                  background:
                    paymentMethod === "paypal" ? "#d15242" : "#f7f7f7",
                  height: 50,
                  width: userLocation === "NG" ? "50%" : "100%",
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: userLocation != "NG" ? 20 : 0,
                  borderBottomLeftRadius: userLocation != "NG" ? 20 : 0,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  padding: 5,
                }}
              >
                <img
                  src={paypalimg}
                  style={{
                    height: "100%",
                    width: "50%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            {paymentMethod === "bank" ? (
              userLocation === "NG" && (
                <div>
                  {" "}
                  <div
                    style={{
                      marginTop: 20,
                      background: "#f7f7f7",
                      borderRadius: 20,
                      minHeight: "70%",
                      padding: 15,
                      // height: "100%",
                    }}
                  >
                    <h1>
                      Note: kindly transfer exact amount to the account details
                      below, then click on *I have paid* after the transfer is
                      successful. Payments will be promptly reviewed and
                      approved, and a confirmation email will be sent to your
                      email address.
                    </h1>
                    <p
                      style={{
                        color: "#aaa",
                        fontSize: 14,
                        marginTop: 10,
                      }}
                    >
                      Any payment-related concerns should be directed to:
                      <a
                        style={{
                          color: "#d15242",
                        }}
                        href="mailto:info@e1xacademy.net?subject=Payment&body=Hello E1x Academy ..?"
                      >
                        info@e1xacademy.net
                      </a>
                    </p>
                    <div style={{ marginTop: 20 }}>
                      <p
                        style={{
                          color: "#d15242",
                        }}
                      >
                        Account Number :
                      </p>
                      <h1
                        style={{
                          fontSize: 25,
                          fontWeight: 500,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        0017286911
                      </h1>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <p
                        style={{
                          color: "#d15242",
                        }}
                      >
                        Bank Name :
                      </p>
                      <h1
                        style={{
                          fontSize: 25,
                          fontWeight: 500,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        Sterling Bank Plc
                      </h1>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <p
                        style={{
                          color: "#d15242",
                        }}
                      >
                        Company Account Name :
                      </p>
                      <h1
                        style={{
                          fontSize: 25,
                          fontWeight: 500,
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        E-DoubleOne Concept
                      </h1>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "#f7f7f7",
                      minHeight: 100,
                      marginTop: 20,
                      padding: 15,
                      borderRadius: 20,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => {
                        toast.success(
                          "Payment will be review and a mail will be sent once approved"
                        );
                        onClose();
                      }}
                      style={{
                        width: "100%",
                        // width: mobile ? "100%" : "20%",
                        // marginTop: 50,
                      }}
                      className="
                    w-full sm:w-auto
                    rounded
                    text-xs sm:text-sm md:text-lg font-bold text-white
                    px-4 sm:px-4 md:px-6 py-4 sm:py-6 md:py-3
                    bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#d15242_50%] to-[#2c75e4_50%]
                    transition-all delay-0 duration-300 ease-out cursor-pointer
                  "
                    >
                      I Have Paid
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div>
                {" "}
                <div
                  style={{
                    marginTop: 20,
                    background: "#f7f7f7",
                    borderRadius: 20,
                    minHeight: "70%",
                    padding: 15,
                    // height: "100%",
                  }}
                >
                  <h1>
                    Note: Paying with paypal will be automatically approved and
                    you will a instant access to your course
                  </h1>
                  <p
                    style={{
                      color: "#aaa",
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Complains about payment should be be sent to this mail :
                    <a
                      style={{
                        color: "#d15242",
                      }}
                      href="mailto:info@e1xacademy.net?subject=Payment&body=Hello E1x Academy ..?"
                    >
                      info@e1xacademy.net
                    </a>
                  </p>
                </div>
                <div
                  style={{
                    background: "#f7f7f7",
                    minHeight: 100,
                    marginTop: 20,
                    padding: 15,
                    borderRadius: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={() => {
                      payPalStart();
                    }}
                    style={{
                      width: "100%",
                      // width: mobile ? "100%" : "20%",
                      // marginTop: 50,
                    }}
                    className="
                  w-full sm:w-auto
                  rounded
                  text-xs sm:text-sm md:text-lg font-bold text-white
                  px-4 sm:px-4 md:px-6 py-4 sm:py-6 md:py-3
                  bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#d15242_50%] to-[#2c75e4_50%]
                  transition-all delay-0 duration-300 ease-out cursor-pointer
                "
                  >
                    Proceed With Payment
                  </button>
                </div>
              </div>
            )}
          </div>
          {!mobile && (
            <div
              style={{
                width: mobile ? "100%" : "49%",

                height: "100%",
              }}
            >
              <img
                src={course?.images && course?.images[0]}
                alt={course?.title}
                className="w-full h-full object-cover"
                style={{
                  height: 300,
                  borderRadius: 20,
                }}
              />
              <h1
                style={{
                  fontWeight: 500,
                  fontSize: 25,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                {course?.title}
              </h1>
              <VonDisplayText
                htmlContent={course?.what_you_will_learn?.slice(0, 260)}
              />
              <div
                style={{
                  border: "1px solid #aaa",
                  minHeight: 100,
                  borderRadius: 20,
                  marginTop: 40,
                  padding: 15,

                  bottom: 30,
                  // left: 0,
                  right: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <h1
                    style={{
                      fontSize: 18,
                    }}
                  >
                    Course Price :{" "}
                  </h1>
                  <h1>
                    {currencyFormatter(price, {
                      currency: currency,
                    })}
                  </h1>
                </div>
                <div
                  style={{
                    height: 1,
                    background: "#aaa",
                    width: "100%",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                ></div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <h1
                    style={{
                      fontSize: 18,
                    }}
                  >
                    Total Price :{" "}
                  </h1>
                  <h1>
                    {" "}
                    {currencyFormatter(price, {
                      currency: currency,
                    })}
                  </h1>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

import { differenceInMinutes, formatDistanceToNow } from "date-fns";
import { child, get, getDatabase, push, ref, set } from "firebase/database";
import { app } from "./firebase";
export const currencyFormatter = (value, options = {}) => {
  const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: ",",
    decimalSeparator: ".",
    symbol: "$",
  };

  if (typeof value !== "number") value = 0.0;
  options = { ...defaultOptions, ...options };

  switch (options.currency) {
    case "USD":
      options.symbol = "$";
      break;
    case "NGN":
      options.symbol = "₦";
      break;
    default:
      options.symbol = options.symbol || "$"; // Fallback to default symbol if not provided
      break;
  }

  value = value.toFixed(options.significantDigits);

  const [currency, decimal] = value.split(".");
  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${options.decimalSeparator}${decimal}`;
};

export const formatWhatsAppTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, seconds);

  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return date.toLocaleTimeString([], options);
};

export const getRelativeTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const now = new Date();
  const date = new Date(now);
  date.setHours(hours, minutes, seconds);

  const diffInMinutes = differenceInMinutes(now, date);

  if (diffInMinutes <= 5) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else {
    return formatWhatsAppTime(timeString);
  }
};
export function getDescriptiveName(path) {
  switch (path) {
    case "/":
      return "Home";
    case "/about":
      return "About Us";
    case "/contact":
      return "Contact Page";
    case "/checkout":
      return "Checkout";
    case "/payment-complete/:reference":
      return "Payment Complete";
    case "/payment":
      return "Payment";
    case "/success":
      return "Successful Payment";
    case "/online-course":
      return "Online Courses";
    case "/online-course/:uuid":
      return "Online Course Detail";
    case "/course-video/:uuid":
      return "Online Course Video";
    case "/live-course":
      return "Live Sessions";
    case "/sponsor/participate":
      return "Become a Sponsor";
    case "/live-course/:uuid":
      return "Live Course Detail";
    case "/sign-in":
      return "Sign In";
    case "/sign-up":
      return "Sign Up";
    case "/verify-email":
      return "Verify Email";
    case "/all-courses":
      return "All Courses";
    case "/dashboard":
      return "Dashboard";
    case "/dashboard/my-course":
      return "My Course";
    case "/reset-password":
      return "Reset Password";
    case "/sponsor":
      return "Sponsor";
    case "/ba-registration":
      return "Business Analyst Registration";
    case "/data-analyst-may-cohort-2024-form":
      return "Data Analyst May Cohort 2024 Form";
    case "/data-analyst-may-cohort-form":
      return "Data Analyst May Cohort Form";
    case "/privacy-policy":
      return "Privacy Policy";
    case "/forgot-password":
      return "Forgotten Password";
    case "/invite":
      return "Engagement";
    case "/vonverify":
      return "Von Verify Email";
    case "/*":
      return "Not Found";
    default:
      return "Unknown Page"; // Default for unmapped paths
  }
}
export const checkForEmptyFields = (formData) => {
  const emptyFields = [];
  for (const [key, value] of Object.entries(formData)) {
    if (value?.trim() === "") {
      emptyFields?.push(key);
    }
  }
  return emptyFields.length > 0
    ? `Empty fields: ${emptyFields.join(", ")}`
    : null;
};
export const convertTimeToVideoTimeStamp = (duration) => {
  const [hoursStr, minutesStr] = duration.split(":");
  const hours = parseInt(hoursStr, 10);
  const minutes = parseInt(minutesStr.replace("Mins", ""), 10);

  // Convert hours and minutes to seconds
  const totalSeconds = hours * 3600 + minutes * 60;

  // Convert total seconds to video timestamp format
  const timestamp = totalSeconds + 1 / 1000; // Adding 1 millisecond for accuracy

  return timestamp;
};
export const isValidDate = (dateString) => {
  // Regular expression to match the format "YYYY-MM-DD"
  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

  // Check if the date string matches the format
  if (!dateFormat.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const [year, month, day] = dateString.split("-").map(Number);

  // Get the current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Months are zero indexed in JavaScript
  const currentDay = currentDate.getDate();

  // Check if the date matches the current year, month, and day
  return year === currentYear && month === currentMonth && day === currentDay;
};

const DateValidator = () => {
  const dateToCheck = "2024-04-11"; // Change this to test with different dates
  const isValid = isValidDate(dateToCheck);

  return (
    <div>
      <p>Date: {dateToCheck}</p>
      <p>Matches Current Date: {isValid ? "Yes" : "No"}</p>
    </div>
  );
};

export default DateValidator;

export const convertVideoTimeStampToPercent = (
  videoTimestamp,
  totalDuration
) => {
  // Convert video timestamp to seconds
  const totalSeconds = parseFloat(videoTimestamp);

  // Calculate percentage completion
  const percent = (totalSeconds / totalDuration) * 100;

  return percent.toFixed(2); // Adjust the number of decimal places as needed
};
export function addObjectToFirebase(
  userId,
  collectionName,
  objectId,
  objectData
) {
  try {
    const db = getDatabase(app);
    if (!db) {
      console.error("Error: Database instance is not available.");
      return;
    }

    set(ref(db, `${collectionName}/` + `${userId?.toString()}`), {
      ...objectData,
    });
    // const databaseRef = ref(database, `${userId.toString()}/${collectionName}`);
    // set((databaseRef, objectId.toString()), {
    //   nam: "ghjk",
    // });
  } catch (error) {
    console.error("Error adding object to Firebase: ", error);
  }
}
export const fetchUserLocation = () => {
  return fetch("https://ipinfo.io/json")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch user location");
      }
      return response.json();
    })
    .then((data) => {
      return data?.country;
      // Assuming the country is sufficient for determining currency
    })
    .catch((error) => {
      console.error("Error getting user location:", error);
    });
};
export const fetchSignUpLocation = () => {
  return fetch("https://ipinfo.io/json")
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch user location");
      }
      return response.json();
    })
    .then((data) => {
      return data;
      // Assuming the country is sufficient for determining currency
    })
    .catch((error) => {
      console.error("Error getting user location:", error);
    });
};
export const extractIdFromUrl = (url) => {
  // Regular expression to match the playback ID
  const regex = /playback\/(\d+)\//;
  const match = url?.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};
export const validatePassword = (password) => {
  const errors = {};
  const minLength = 8;
  const capitalRegex = /.*[A-Z].*/;
  const lowercaseRegex = /.*[a-z].*/;
  const specialCharacterRegex = /.*[!@#$%^&*?%|\{}/*].*/;
  const numberRegex = /.*[0-9].*/;
  errors.lengthError = password.length >= minLength;
  errors.uppercaseError = capitalRegex.test(password);
  errors.lowercaseError = lowercaseRegex.test(password);
  errors.specialCharacterError = specialCharacterRegex.test(password);
  errors.numberRegex = numberRegex.test(password);
  return errors;
};
export function getMimeType(url) {
  // Example implementation: determine MIME type based on URL or other logic
  if (url.endsWith(".pdf")) {
    return "application/pdf";
  } else if (url.endsWith(".doc") || url.endsWith(".docx")) {
    return "application/msword";
  } else if (url.endsWith(".docx") || url.endsWith(".docx")) {
    return "application/msword";
  } else if (url.endsWith(".xls") || url.endsWith(".xlsx")) {
    return "application/vnd.ms-excel";
  } else if (url.endsWith(".ppt") || url.endsWith(".pptx")) {
    return "application/vnd.ms-powerpoint";
  } else {
    // Fallback to generic MIME type for unknown types
    return "application/octet-stream";
  }
}

export const sendMessage = (id, data) => {
  const db = getDatabase(app);
  push(ref(db, "chats/" + id), {
    id: data?.id,
    type: data?.type,
    image: data?.image,
    message: data?.message,
    time: new Date().toString(),
    docUrl: data?.docUrl,
    videoUrl: data?.videoUrl,
    name: data?.name,
    replyId: data?.replyId,
  });
  return true;
};
export const isPasswordValid = (errors) => {
  return (
    errors.lengthError &&
    errors.uppercaseError &&
    errors.lowercaseError &&
    errors.specialCharacterError &&
    errors.numberRegex
  );
};
export const date = (isoString = "") => {
  const date = new Date(isoString);
  if (isoString != "") {
    const year = date.getFullYear(); // Get the year (e.g., 2023)
    const month = date.getMonth() + 1; // Get the month (0-based, so add 1 for January-December)
    const day = date.getDate(); // Get the day of the month

    const formattedDate = day + "/" + month + "/" + year;
    return formattedDate;
  } else {
    return null;
  }
};
export const updateStarCount = (element, count) => {
  // element.textContent = `Star Count: ${count}`;
};

export const FetchCurrentWatchingCourse = async (user_id, uuid) => {
  try {
    const dbRef = ref(getDatabase());
    const snapshot = await get(child(dbRef, `${user_id}/${uuid}`));

    if (snapshot.exists()) {
      return {
        status: true,
        data: snapshot.val(),
      };
    } else {
      return {
        status: false,
        data: null,
      };
    }
  } catch (error) {
    return {
      status: false,
      data: error,
    };
  }
};
// export const FetchCurrentWatchingCourse = async (user_id) => {
//   const dbRef = ref(getDatabase());

//   get(child(dbRef, `watchcourses/${user_id.toString()}`))
//     .then((snapshot) => {
//       if (snapshot.exists()) {
//         console.log(snapshot.val());
//         return {
//           status: true,
//           data: snapshot.val(),
//         };
//       } else {
//         return {
//           status: false,
//           data: null,
//         };
//       }
//     })
//     .catch((error) => {
//       console.log(error);
//       return {
//         status: false,
//         data: error,
//       };
//     });
// };
export const Referral = [
  "Search engines (other websites, blogs, videos, pictures)",
  "Internet ads",
  "Social media",
  "Customer review sites",
  "Comparison sites",
  "Nysc",
  "Customer testimonials",
  "Other",
];
export function isDateValid(solutionReleaseAt) {
  const currentDate = new Date();
  const releaseDate = new Date(solutionReleaseAt);

  return currentDate <= releaseDate;
}
export const errorMessage = (error) => {
  return (
    error?.response?.data?.detail ||
    error?.response?.data?.error?.message ||
    error?.response?.data?.msg ||
    error?.response?.data?.cause ||
    (error?.response?.data?.email && Array.isArray(error.response.data.email)
      ? error.response.data.email[0]
      : undefined) ||
    error?.response?.data?.message ||
    error?.response?.data?.error?.message ||
    error?.message ||
    (typeof error === "string" && "Something went wrong")
  );
};
